<template>
    <div class="setting">
        <div class="person fw_700">个人信息</div>
        <div class="nick_name">
            <p style="width: 80px">昵称:</p>
            <div class="nick_ipt">{{ nickName }}</div>
        </div>
        <div class="app_list fw_700">
            已绑定小程序列表<el-button
                type="primary"
                :icon="Plus"
                style="margin-left: 10px"
                @click="handleCreateMini"
                >绑定新的小程序</el-button
            >
        </div>
        <div class="table" style="width: 100%">
            <el-table
                :data="userInfo?.organizations || []"
                border
                style="width: 100%"
            >
                <el-table-column prop="appPic" label="小程序头像">
                    <template #default="scope">
                        <img
                            :src="scope.row.logo"
                            style="width: 100px; height: 100px; cursor: pointer"
                            @click="handlePreviewImage(scope.row.logo)"
                        />
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="小程序名称" />
                <el-table-column prop="appId" label="小程序APPID" />
                <el-table-column prop="date" label="绑定时间" />
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-button @click="handleEditMini(scope.row)"
                            >编辑</el-button
                        >
                        <el-button
                            type="primary"
                            @click="
                                scope.row.appId
                                    ? handleSkipConfig(scope.row)
                                    : handleAuthMini(scope.row)
                            "
                            >{{
                                scope.row.appId ? "去工作台" : "授权小程序"
                            }}</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-dialog
            v-model="dialogVisible"
            :title="currentMiniInfo?.id ? '编辑' : '新增'"
            width="30%"
            :before-close="handleModalVisible"
        >
            <el-form label-width="120px" style="width: 60%">
                <el-form-item label="小程序头像：">
                    <el-image
                        class="td-img"
                        style="width: 120px"
                        :src="currentMiniInfo.logo"
                        v-if="currentMiniInfo.logo"
                        :preview-src-list="[currentMiniInfo.logo]"
                        fit="fill"
                        title="点击查看大图"
                    />
                    <el-upload
                        ref="upload"
                        class="upload-demo"
                        action="#"
                        multiple
                        :limit="1"
                        style="display: inline-block"
                        :http-request="(file) => uploadImg(file)"
                        :on-remove="handleRemoveImg"
                    >
                        <span
                            style="
                                cursor: pointer;
                                color: #409eff;
                                padding-top: 10px;
                            "
                            >{{
                                currentMiniInfo.logo ? "修改头像" : "上传头像"
                            }}</span
                        >
                    </el-upload>
                </el-form-item>
            </el-form>
            <el-form label-width="120px" style="width: 60%">
                <el-form-item label="小程序名称：">
                    <el-input
                        v-model="currentMiniInfo.title"
                        placeholder="请输入名称"
                    />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="handleModalVisible">取消</el-button>
                    <el-button type="primary" @click="handleSaveMini">
                        确定
                    </el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog
            v-model="imageVisible"
            title="预览图片"
            width="80%"
            :before-close="handlePreviewImage"
        >
            <div
                style="
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                "
            >
                <img
                    :src="previewImageUrl"
                    style="width: 100%; max-width: 600px"
                />
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="handlePreviewImage">取消</el-button>
                </span>
            </template>
        </el-dialog>

        <el-dialog
            v-model="authModalVisible"
            title="扫码授权"
            width="30%"
            :before-close="handleAuthVisible"
        >
            <div
                style="
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                "
            >
                <canvas
                    id="QRCode_header"
                    style="width: 280px; height: 280px"
                ></canvas>

                <el-button
                    style="margin-top: 20px"
                    type="primary"
                    @click="handleAuthSucc"
                    >授权成功</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { ref, reactive, nextTick } from "vue";
import { ElMessage } from "element-plus";
import { Plus } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import { formatDate } from "@/utils/utils";
import { getUserInfo } from "@/services/modules/user";
import {
    orgAdd,
    orgAuthInfo,
    orgAuthStatus,
} from "@/services/modules/organization";
import Uploader from "@/utils/upload/ossupload";
import QRCode from "qrcode"; //引入生成二维码插件

export default {
    setup() {
        const router = new useRouter();
        const userInfo = reactive({});
        const userNum = ref("99887766");
        const nickName = ref("");
        const dialogVisible = ref(false);
        const currentMiniInfo = reactive({ title: "", logo: "" });
        const upload = ref("");
        const imageVisible = ref(false);
        const previewImageUrl = ref("");
        const authInfo = reactive({});
        const authModalVisible = ref(false);
        let timer = null;
        let timerCount = 0;

        const userInfoInit = async () => {
            getUserInfo()
                .then((res) => {
                    let info = res.data;
                    if (info) {
                        info.organizations = info.organizations.map((item) => {
                            return {
                                ...item,
                                appId:
                                    item.authorizer &&
                                    item.authorizer.length > 0
                                        ? item.authorizer[0].appId
                                        : "",
                                date: formatDate(
                                    item.createDate,
                                    "yyyy-MM-dd hh:mm:ss"
                                ),
                            };
                        });
                    }
                    Object.assign(userInfo, res.data);
                    console.log("userInfo", userInfo);
                    nickName.value = userInfo?.adminUser?.userName;
                })
                .catch((err) => {
                    Object.assign(
                        userInfo,
                        localStorage.getItem("userInfo")
                            ? JSON.parse(localStorage.getItem("userInfo"))
                            : {}
                    );
                    console.log("userInfo", userInfo);
                    nickName.value = userInfo?.adminUser?.userName;
                });
        };

        userInfoInit();

        console.log("nickName", nickName);
        const tableData = reactive([]);

        const confirmName = () => {
            if (nickName.value.length > 20) {
                ElMessage.error("昵称最多20个字");
                nickName.value = "";
                return;
            }
        };

        const handleCreateMini = () => {
            Object.assign(currentMiniInfo, { title: "", logo: "" });
            handleModalVisible();
        };

        const handleModalVisible = () => {
            dialogVisible.value = !dialogVisible.value;
        };

        const handleSaveMini = async () => {
            // const idParams = currentMiniInfo.id
            //     ? { id: currentMiniInfo.id }
            //     : {};
            if (!currentMiniInfo.title) {
                return ElMessage.error("请输入小程序名称");
            }
            let params = {
                ...currentMiniInfo,
            };

            const res = await orgAdd(params);
            console.log(res);
            handleModalVisible();
            if (res.code === 1) {
                ElMessage.success(params.id ? "修改成功" : "新增成功");
                userInfoInit();
                return;
            }

            ElMessage.error(res.msg || res.message || "失败");
        };

        const handleEditMini = (item) => {
            Object.assign(currentMiniInfo, JSON.parse(JSON.stringify(item)));
            handleModalVisible();
        };

        const handleAuthMini = (item) => {
            console.log("item", item);
            Object.assign(currentMiniInfo, JSON.parse(JSON.stringify(item)));
            orgInfo();
            // router.push({
            //     path: "/home",
            //     query: {
            //         orgId: item.id,
            //         // appId: item.appId || "",
            //     },
            // });
        };

        const uploadImg = (params) => {
            Uploader.put(
                params.file,
                params.file.type,
                async (url) => {
                    console.log("----", url);
                    currentMiniInfo.logo = url;
                    console.log(upload.value);
                    upload.value.clearFiles();
                    ElMessage.success("上传成功");
                },
                (err) => {
                    console.log("----", err);
                    ElMessage.error("上传失败");
                }
            );
        };

        const handleRemoveImg = () => {
            currentMiniInfo.logo = "";
        };

        const handlePreviewImage = (img = "") => {
            previewImageUrl.value = img;
            imageVisible.value = !imageVisible.value;
        };

        const orgInfo = async () => {
            let params = {
                orgId: currentMiniInfo.id,
            };
            const res = await orgAuthInfo(params);
            if (res.code == 1) {
                Object.assign(authInfo, res.data);
                handleAuthVisible();
                return
            }

            ElMessage.error(res.msg || res.message || '授权失败');
        };

        const handleAuthSucc = () => {
            ElMessage.info("查询授权状态中。。。");
            handleAuthVisible();
            orgOneAuthStatus("deep");
        };

        const handleAuthVisible = () => {
            if (!currentMiniInfo.id) {
                return ElMessage.error("请先选择机构");
            }
            authModalVisible.value = !authModalVisible.value;

            if (authModalVisible.value) {
                nextTick(() => {
                    getQRCode(authInfo.preAuthUrl);
                });
            }
        };

        const orgOneAuthStatus = async (type) => {
            const params = {
                orgId: currentMiniInfo.id,
            };

            const res = await orgAuthStatus(params);
            console.log("res", res);
            if (!res.data) {
                if (type == "deep") {
                    if (timerCount + 1 > 3) {
                        ElMessage.error(
                            "查询失败，暂未授权，如果授权后可刷新页面查看状态"
                        );
                    }
                    timeoutAuthStatus();
                } else {
                    ElMessage.error(
                        "查询失败，暂未授权，如果授权后可刷新页面查看状态"
                    );
                }
                return;
            } else {
                ElMessage.error("授权成功");
            }
            userInfoInit();
            clearInterval(timer);
            timer = null;
            timerCount = 0;
        };

        const timeoutAuthStatus = () => {
            timerCount = timerCount + 1;

            if (timerCount > 3) {
                clearInterval(timer);
                timer = null;
                timerCount = 0;
                return;
            }

            timer = setTimeout(() => {
                orgOneAuthStatus("deep");
            }, 4000);
        };

        const getQRCode = (qrUrl) => {
            console.log("qrUrl", qrUrl);
            let opts = {
                errorCorrectionLevel: "H", //容错级别
                type: "image/png", //生成的二维码类型
                quality: 0.3, //二维码质量
                margin: 0, //二维码留白边距
                width: 280, //宽
                height: 280, //高
                text: qrUrl, //二维码内容
                color: {
                    dark: "#333333", //前景色
                    light: "#fff", //背景色
                },
            };

            let msg = document.getElementById("QRCode_header");
            // 将获取到的数据（val）画到msg（canvas）上
            QRCode.toCanvas(msg, qrUrl, opts, function (error) {
                if (error) {
                    console.log("二维码加载失败", error);
                }
            });
        };

        const handleSkipConfig = (item) => {
            router.push({
                path: "/home",
                query: {
                    orgId: item.id
                },
            });
        };

        return {
            tableData,
            userNum,
            nickName,
            confirmName,
            userInfo,
            dialogVisible,
            handleModalVisible,
            handleSaveMini,
            currentMiniInfo,
            Plus,
            handleEditMini,
            handleAuthMini,
            uploadImg,
            handleRemoveImg,
            upload,
            imageVisible,
            handlePreviewImage,
            previewImageUrl,
            handleAuthSucc,
            authModalVisible,
            handleAuthVisible,
            handleCreateMini,
            handleSkipConfig,
        };
    },
};
</script>

<style lang="scss">
.el-image-viewer__wrapper {
    z-index: 9;
}
</style>

<style lang="scss" scoped>
.setting {
    display: flex;
    flex-flow: column;
    background-color: #fff;
    width: 100%;
    padding: 20px;

    & > div {
        margin: 18px 0;
    }

    .fw_700 {
        font-weight: 700;
    }

    .user {
        display: flex;
        align-items: center;

        .user_num {
            margin-left: 15px;
        }
    }

    .nick_name {
        display: flex;
        align-items: center;

        .nick_ipt {
            width: 400px;
            margin-left: -30px;
            margin-right: 10px;
        }
    }

    .table {
        width: 800px;
    }
}
</style>